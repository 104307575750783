<template>
  <b-select id="permission-category-filter" @change="handleInput" class="w-100">
    <b-select-option :value="undefined">{{ $t('LIST_ALL_OPTIONS_LABEL')  | capitalize}}</b-select-option>
    <b-select-option :key="category.id" v-for="category in categories" :value="category.name">{{$t(category.name.replace('_PERMISSION_CATEGORY', '')) | capitalize}}</b-select-option>
  </b-select>
</template>

<script>
import {CapitalFilter, VModel} from "@/mixins";
import PermissionCategories from "@/users/permission-categories/index";

export default {
  name: "PermissionCategorySingleSelector",
  mixins: [VModel, CapitalFilter],
  async mounted() {
    this.categories = await PermissionCategories.findAll();
  },
  data() {
    return {
      categories: []
    }
  }
}
</script>

<style scoped>

</style>
