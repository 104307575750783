<template>
  <div>
    <b-form class="mb-2" inline>
      <div class="w-100 pr-1">
        <permission-category-single-selector @input="search" v-model="filter.text"></permission-category-single-selector>
      </div>
    </b-form>
  </div>
</template>

<script>
import {Filter, VModel} from "@/mixins";
import PermissionCategorySingleSelector from "@/users/permission-categories/PermissionCategorySingleSelector";

export default {
  name: "PermissionListFilter",
  components: {PermissionCategorySingleSelector},
  mixins: [VModel, Filter],
  data() {
    return {
      filter: {}
    }
  },
  methods: {
    search() {
      this.$emit('search', this.filter);
    }
  }
}
</script>

<style scoped>

</style>
